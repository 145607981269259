import { createSlice } from '@reduxjs/toolkit';

const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        previousPage: '',
        reloadContacts: false,
        reload: false,
        contactsData: {},
        kidsData: [],
        siblingsData: [],
        petsData: [],
        achievementsData: []
    },
    reducers: {
        setPreviousPage: (state, action) => {
            state.previousPage = action.payload.previousPage;
        },
        setReloadContacts: (state, action) => {
            state.reloadContacts = action.payload.reloadContacts;
        },
        setReload: (state, action) => {
            state.reload = action.payload.reload;
        },
        setContactsData: (state, action) => {
            state.contactsData = action.payload.contactsData;
        },
        setKidsData: (state, action) => {
            state.kidsData = action.payload.kidsData;
        },
        setSiblingsData: (state, action) => {
            state.siblingsData = action.payload.siblingsData;
        },
        setPetsData: (state, action) => {
            state.petsData = action.payload.petsData;
        },
        setAchievmentsData: (state, action) => {
            state.achievementsData = action.payload.achievementsData;
        }
    }
});

export const { setPreviousPage, setReloadContacts, setReload, setContactsData, setKidsData, setSiblingsData, setPetsData, setAchievmentsData } = contactSlice.actions;
export default contactSlice.reducer;
