// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FULL SCREEN LOADER */
/* .App{position: relative;} */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 50%);
    z-index: 1055;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;padding: 15px;
}
.backdrop>.spinner-border {
    width: 60px;
    height: 60px;
}
.backdrop>p {
    color: #fff;
    margin-top: 18px;
}

@media screen and (max-width: 640px){
    .backdrop p{max-width: 250px;margin: 0 auto;}
}`, "",{"version":3,"sources":["webpack://./src/components/FullScreenLoader/FullScreenLoader.styles.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB,8BAA8B;AAC9B;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB,CAAC,aAAa;AACpC;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,YAAY,gBAAgB,CAAC,cAAc,CAAC;AAChD","sourcesContent":["/* FULL SCREEN LOADER */\n/* .App{position: relative;} */\n.backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgb(0 0 0 / 50%);\n    z-index: 1055;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    text-align: center;padding: 15px;\n}\n.backdrop>.spinner-border {\n    width: 60px;\n    height: 60px;\n}\n.backdrop>p {\n    color: #fff;\n    margin-top: 18px;\n}\n\n@media screen and (max-width: 640px){\n    .backdrop p{max-width: 250px;margin: 0 auto;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
