import React from 'react';

import './FullScreenLoader.styles.css';

const FullScreenLoader = () => {
    return (
        <div className="backdrop">
            <div className="spinner-border text-light"></div>
            {/* <p className='d-inline-flex'>Please do not close or refresh the page during this process.</p> */}
        </div>
    );
};

export default FullScreenLoader;
