import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userInfo: {},
        userData: {},
        userDataForCusId: {},
        signupInfo: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            termsChecked: false,
            privacyChecked: false
        },
        faqs: [],
        plansData: {
            monthlyPlans: [],
            yearlyPlans: [],
            paymentMethods: []
        }
    },
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload.userInfo;
        },
        setUserData: (state, action) => {
            state.userData = action.payload.userData;
        },
        setUserDataCusId: (state, action) => {
            state.userDataForCusId = action.payload.userDataForCusId;
        },
        setSignupInfo: (state, action) => {
            state.signupInfo = action.payload.signupInfo;
        },
        setFaqs: (state, action) => {
            state.faqs = action.payload.faqs;
        },
        setPlansData: (state, action) => {
            state.plansData = action.payload.plansData;
        },
        setYearlyPlans: (state, action) => {
            state.yearlyPlans = action.payload.yearlyPlans;
        },
        setPaymentMethods: (state, action) => {
            state.paymentMethods = action.payload.paymentMethods;
        }
    }
});

export const { setUserInfo, setSignupInfo, setFaqs, setPlansData, setYearlyPlans, setUserData, setUserDataCusId, setPaymentMethods } = userSlice.actions;
export default userSlice.reducer;
