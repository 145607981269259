import axios from 'axios';
const axiosClient = axios.create();
axiosClient.defaults.baseURL = process.env.REACT_APP_API_URL;
axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};

export default axiosClient;

const setAccessToken = accessToken => {
    if (accessToken != null) {
        axiosClient.defaults.headers.Authorization = `Bearer ${accessToken}`;
    }
};

export function getRequest(URL, accessToken) {
    setAccessToken(accessToken);
    return axiosClient.get(`/${URL}`).then(response => response);
}

export function postRequest(URL, payload, accessToken) {
    setAccessToken(accessToken);
    return axiosClient.post(`/${URL}`, payload).then(response => response);
}

export function postRequestFile(URL, payload, accessToken) {
    // setAccessToken(accessToken);
    return axiosClient.post(`/${URL}`, payload, { headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
    } }).then(response => response);
}

export function putRequestFile(URL, payload, accessToken) {
    // setAccessToken(accessToken);
    return axiosClient.put(`/${URL}`, payload, { headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
    } }).then(response => response);
}

export function putRequest(URL, payload, accessToken) {
    setAccessToken(accessToken);
    axiosClient.defaults.headers.accessToken = accessToken;
    return axiosClient.put(`/${URL}`, payload).then(response => response);
}

export function deleteRequest(URL, payload, accessToken) {
    setAccessToken(accessToken);
    return axiosClient.delete(`/${URL}`, { data: payload }).then(response => response);
}

export function setupInterceptors(navigate) {
    axiosClient.interceptors.response.use(
        function(response) {
            // Dispatch any action on success
            return response;
        },
        function(error) {
            if (error.response?.status === 401) {
                localStorage.clear();
                navigate('/');
                // window.location.href = '/';
            }
            return Promise.reject(error);
        }
    );
};
