import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        chatContacts: [],
        recentChats: [],
        aiQueryChatHistory: [],
        aiAddChatHistory: [],
        aiUpdateChatHistory: []
    },
    reducers: {
        setChatContacts: (state, action) => {
            state.chatContacts = action.payload.chatContacts;
        },
        setRecentChats: (state, action) => {
            state.recentChats = action.payload.recentChats;
        },
        setAIQueryChatHistory: (state, action) => {
            state.aiQueryChatHistory = action.payload.aiQueryChatHistory;
        },
        setAIAddChatHistory: (state, action) => {
            state.aiAddChatHistory = action.payload.aiAddChatHistory;
        },
        setAIUpdateChatHistory: (state, action) => {
            state.aiUpdateChatHistory = action.payload.aiUpdateChatHistory;
        }
    }
});

export const { setChatContacts, setRecentChats, setAIQueryChatHistory, setAIAddChatHistory, setAIUpdateChatHistory } = chatSlice.actions;
export default chatSlice.reducer;
