import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        recentNudges: [],
        recentChats: [],
        counters: {
            total_contact_count: 0,
            total_nudges_count: 0,
            total_queries_count: 0,
            used_contact: 0,
            used_nudges: 0,
            used_queries: 0
        }
    },
    reducers: {
        setRecentNudgesData: (state, action) => {
            state.recentNudges = action.payload.recentNudges;
        },
        setRecentChatsData: (state, action) => {
            state.recentChats = action.payload.recentChats;
        },
        setCountersData: (state, action) => {
            state.counters = action.payload.counters;
        }
    }
});

export const { setRecentNudgesData, setRecentChatsData, setCountersData } = dashboardSlice.actions;
export default dashboardSlice.reducer;
