import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
    name: 'global',
    initialState: {
        isFullScreenLoading: false,
        isEmailVerified: false,
        reloadCounters: false,
        reloadNotifications: false,
        reloadPlanNotifications: false,
        reloadNudgeNotifications: false,
        reloadAllNudgeNotifications: false,
        latestPlan: {}
    },
    reducers: {
        setIsFullScreenLoading: (state, action) => {
            state.isFullScreenLoading = action.payload.isFullScreenLoading;
        },
        setIsEmailVerified: (state, action) => {
            state.isEmailVerified = action.payload.isEmailVerified;
        },
        setReloadCounters: (state, action) => {
            state.reloadCounters = action.payload.reloadCounters;
        },
        setReloadNotifications: (state, action) => {
            state.reloadNotifications = action.payload.reloadNotifications;
        },
        setReloadPlanNotifications: (state, action) => {
            state.reloadPlanNotifications = action.payload.reloadPlanNotifications;
        },
        setReloadAllNudgeNotifications: (state, action) => {
            state.reloadAllNudgeNotifications = action.payload.reloadAllNudgeNotifications;
        },
        setReloadNudgeNotifications: (state, action) => {
            state.reloadNudgeNotifications = action.payload.reloadNudgeNotifications;
        },
        setLatestPlan: (state, action) => {
            state.latestPlan = action.payload.latestPlan;
        }
    }
});

export const { setIsFullScreenLoading, setIsEmailVerified, setReloadCounters, setReloadNotifications, setReloadPlanNotifications, setLatestPlan, setReloadAllNudgeNotifications, setReloadNudgeNotifications } = globalSlice.actions;
export default globalSlice.reducer;
