import { configureStore } from '@reduxjs/toolkit';
import globalSlice from './reducers/global-reducer';
import chatSlice from './reducers/chat-reducer';
import dashboardSlice from './reducers/dashboard-reducer';
import userSlice from './reducers/user-reducer';
import contactSlice from './reducers/contact-reducer';

const store = configureStore({
    reducer: {
        global: globalSlice,
        chat: chatSlice,
        dashboard: dashboardSlice,
        user: userSlice,
        contact: contactSlice
    }
});

export default store;
