import React, { Suspense, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';

import { setUserInfo } from '@store/reducers/user-reducer';
import { setupInterceptors } from '@utils/axios-client';

const AdminRoutes = React.lazy(() => import('@components/Routes/AdminRoutes/Index'));
const UserRoutes = React.lazy(() => import('@components/Routes/UserRoutes/Index'));

// const useUserInfo = () => {
//     const userInfo = useMemo(() => {
//         const info = localStorage.getItem('userInfo');
//         return info ? JSON.parse(info) : {};
//     }, []);
//     return userInfo;
// };

function App() {
    const [role, setRole] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isFullScreenLoading } = useSelector(state => state.global);
    // const userInfo = useUserInfo();
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};

    useEffect(() => {
        setupInterceptors(navigate);
        dispatch(setUserInfo({ userInfo: userInfo }));
    }, []);

    useEffect(() => {
        if (userInfo && userInfo?.role_id == '1' || location.pathname.includes('admin')) {
            setRole('admin');
        } else if (userInfo && userInfo?.role_id == '2' || !location.pathname.includes('admin')) {
            setRole('user');
        }
    }, [location?.pathname]);

    return (
        <div className="App">
            {role == 'user' && (
                <Suspense fallback={<FullScreenLoader />}>
                    <UserRoutes />
                </Suspense>
            )}
            {role == 'admin' && (
                <Suspense fallback={<FullScreenLoader />}>
                    <AdminRoutes />
                </Suspense>
            )}
            <ToastContainer position='bottom-right' />
            {isFullScreenLoading ? <FullScreenLoader /> : ''}
        </div>
    );
}

export default App;
